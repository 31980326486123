import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import Swiper from "swiper";
import {
  Pagination,
  EffectFade,
  Autoplay,
  Navigation,
  A11y,
} from "swiper/modules";

Array.from(document.querySelectorAll(".js-slider-banner")).forEach(
  (container) => {
    const slider = container.querySelector(".swiper");
    const pagination = container.querySelector(".swiper-pagination");

    const swiper = new Swiper(slider, {
      modules: [Pagination, EffectFade, Autoplay, A11y],
      loop: true,
      autoHeight: true,
      speed: 1000,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: pagination,
        clickable: true,
      },
      autoplay: {
        delay: 8000,
      },
    });
  }
);

Array.from(document.querySelectorAll(".js-product-gallery")).forEach(
  (container) => {
    const slider = container.querySelector(".swiper");

    if (!slider) {
      return;
    }

    const swiper = new Swiper(slider, {
      modules: [Navigation, EffectFade, A11y],
      simulateTouch: false,
      loop: true,
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      },
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
    });
  }
);
